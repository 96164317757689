var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e(), _c('div', {
          staticClass: "cyui-custom-navbar"
        }, [_c('cyui-navbar', {
          attrs: {
            "swipe-threshold": 2,
            "show-title-number": "",
            "list-data": _vm.categoryInfo.listData
          },
          on: {
            "change": _vm.handleTabChange
          },
          model: {
            value: _vm.categoryInfo.value,
            callback: function ($$v) {
              _vm.$set(_vm.categoryInfo, "value", $$v);
            },
            expression: "categoryInfo.value"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-scroller-panel"
        }, [_c('cyui-scroller', {
          ref: "scroller",
          staticClass: "wrapper",
          attrs: {
            "scrollbar": _vm.scrollbarConfig,
            "pullDownRefresh": _vm.pullDownRefreshConfig,
            "pullUpLoad": _vm.pullUpLoadConfig,
            "startY": parseInt(_vm.startY)
          },
          on: {
            "pullingDown": _vm.handlePullingDown,
            "pullingUp": _vm.handlePullingUp
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_vm.listData.length ? _c('div', {
                staticClass: "cyui-custom-list"
              }, _vm._l(_vm.listData, function (item, index) {
                return _c('custom-list-item', {
                  key: index,
                  attrs: {
                    "detail-info": item
                  },
                  on: {
                    "go-detail": function ($event) {
                      return _vm.goDetail(item);
                    }
                  }
                });
              }), 1) : _vm._e()];
            },
            proxy: true
          }])
        })], 1), _vm.listData.length === 0 && _vm.isLoading === false ? _c('no-data', {
          attrs: {
            "msg": _vm.noDataMessage
          },
          scopedSlots: _vm._u([{
            key: "btn",
            fn: function () {
              return [_vm.isRefreshPage ? _c('div', {
                staticClass: "cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius",
                on: {
                  "click": _vm.handleRefreshPage
                }
              }, [_vm._v(" 刷新重试 ")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 3275925837)
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };